import { VStack, Text } from "@chakra-ui/react";
import CabeceraSitio from "../componets/CabeceraSitio";


const ServiciosContent = (location, title, descripcion, imgSection) => {
    if (location == 1) {
        return <>
            <div className="divServiciosPage">
                <div className={`divServicioContentImg--${imgSection}`}>
                </div>
                <div className="divServiciosPageContent--Right">
                    <Text className="textServiciosTitle">
                        {title}
                    </Text>
                    <div />
                    <Text className="parrafos">
                        {descripcion}
                    </Text>
                </div>
            </div>
            <div className="divServiciosDivisor">
            </div>
        </>
    } else {
        return <>
            <div className="divServiciosPage">
                <div className="divServiciosPageContent">
                    <Text className="textServiciosTitle">
                        {title}
                    </Text>
                    <div />
                    <Text className="parrafos">
                        {descripcion}
                    </Text>
                </div>
                <div className={`divServicioContentImg--${imgSection}`}>
                </div>
            </div>
            <div className="divServiciosDivisor">
            </div>
        </>
    }
}

export default function Servicios() {

    return <>
        <CabeceraSitio seccion='Servicios' />
        <VStack className="divDefultConfig--Servicios">
            <div className="divServiciosDivisor">
            </div>
            {ServiciosContent(1, 'Contrucciones', 'Nuestro personal se encuentra ampliamente capacitado para cualquier tipo de construcción llámese edificios, cabañas, residenciales entre otros...', "sectionConstru")}
            {ServiciosContent(2, 'Consultoría y tramitología', 'Contamos con una amplia experiencia en tramitación de condominios, habitaciones, apartamentos, casas y parques industriales.', "sectionTrami")}
            {ServiciosContent(1, 'Remodelaciones, ampliaciones y acabados finales', 'Ofrecemos servicios en remodelaciones, ampliaciones y acabados finales de excelente calidad y precio para sus proyectos.', 'sectionRemo')}
            {ServiciosContent(2, 'Diseños para proyectos', 'Ofrecemos todo tipo de diseños para sus proyectos, arquitectónicos, estructurales, mecánicos, geotécnicos. hidráulicos, infraestructurales.', 'sectionDisenos')}
            {ServiciosContent(1, 'Gestion de proyectos de construccion', 'Planificación y realización de los estudios preliminares, para lograr identificar las necesidades del cliente y dar la respectiva dirección del proyecto.', 'sectionProyectos')}
            {ServiciosContent(2, 'Inspección de obra', 'Déjenos llevar el proceso y la calidad de sus proyectos, para garantizarles, la correcta elaboración y finalización de estos.', 'sectionInspeccion')}
        </VStack>
    </>
}