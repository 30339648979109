import { AspectRatio, Box, HStack, Image, Link, Text, VStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import CabeceraSitio from "../componets/CabeceraSitio";
import SmartSlider from "react-smart-slider";
import { useRef } from "react";
import useWindowDimensions from "../componets/useDimensions";

// DummyCaption component for example
const DummyCaption = (props) => (
    <div id='divNosotrosSliderShow'>
        <VStack>
            <Text id="sliderShowNosotros--Title" >
                {props.ano}
            </Text>
            <Text id="sliderShowNosotros--SubTitle" fontSize={['15px', '20px', '25px']} lineHeight={['15px', '20px', '30px']}>
                {props.info}
            </Text>
        </VStack>
    </div>
)

export default function Nosotros() {
    const [seccion, setSeccion] = useState('Mision')
    const [datesViewSeccionInfo, setDatesViewSeccionInfo] = useState()
    const clickRef = useRef(null)
    const [activeLink, setActiveLink] = useState(0);
    const { width } = useWindowDimensions();

    const listOptions = [
        { text: "Mision" },
        { text: "Vision" },
        // { text: "Valores" }
    ];

    const slidesArray = [
        {
            childrenElem: <DummyCaption ano="1990"
                info="Año crucial para nuestra empresa debido a que en este el Señor Freddy García, comenzó a sus 17 años en su primer trabajo como peón en el área de la construcción, 
                a partir de aquí fue tomando conocimiento con diferentes patronos, al poco tiempo vio que tenía una habilidad innata en los procesos constructivos, aprendía con facilidad, y le apasionaba lo que hacía." />
        },
        {
            childrenElem: <DummyCaption ano="2000"
                info="Llegado a este año y con un amplio conocimiento, El señor Freddy 
                García se lanza de manera independiente con su primera pequeña empresa llamada Multiservicios García."
            />
        },
        {
            childrenElem: <DummyCaption ano="2010"
                info="En el 2010, cuando el hijo del Señor Freddy García, entro a secundaria, le ofreció la posibilidad a este de ir y ayudarle a él en su labor, 
                en los tiempos de vacaciones de verano del colegio, con el propósito mostrarle la importancia de lo que es el trabajo, es aquí cuando a su hijo y su padre, 
                nace el deseo de algún día tener su propia empresa, por esto Yehudy Steve se propuso como meta ser Ingeniero en Construcción, con el fin de lograr algún día formar una empresa sólida, con muchas capacidades."
            />
        },
        {
            childrenElem: <DummyCaption ano="2014"
                info="Es en esta etapa de secundaria donde Yehudy Steve conoce a un gran amigo como lo es Robín Alpizar, quien juntos dentro de unos años se propusieron unir fuerzas para la obtención de este gran sueño." />
        },
        {
            childrenElem: <DummyCaption ano="2020"
                info="Año memorable para nosotros como empresa, es en este instante donde el esfuerzo de años, de trabajo de estudio, y de resiliencia, dan sus frutos, 
                por fin se funda Construtora Summa Ga Sa. Aunque fue un inicio complicado, se logró superar todos los obstáculos, pudiendo salir a flote para sostener y 
                seguir trabajando por nuestra misión de ser los mejores en el campo."
            />
        },
    ];

    useEffect(() => {
        if (seccion == 'Mision') {
            setDatesViewSeccionInfo(<>
                <VStack>
                    <Text className="parrafos">
                        Llevar a cabo todos nuestros proyectos de la manera más óptima y eficiente, ser innovadores buscando tomar las mejores decisiones según la necesidad de nuestros clientes.
                    </Text>
                </VStack>
            </>)
        } else {
            if (seccion == 'Vision') {
                setDatesViewSeccionInfo(<>
                    <VStack>
                        <Text className="parrafos">
                            Nuestro deseo es ir creciendo exponencialmente con el pasar del tiempo, logrando de esta manera convertirnos en la mejor empresa constructora de la región.
                        </Text>
                    </VStack>
                </>)
            }
        }


    }, [seccion])

    return <>
        <CabeceraSitio seccion='Nosotros'></CabeceraSitio>
        <div className="divDefultConfig--Nosotros">
            <div>
                <Text className='title'>Nosotros</Text>
                <div className="divNosotrosFondoMovimiento" >
                    <video autoplay="autoplay" loop="loop" muted defaultMuted playsinline oncontextmenu="return false;" preload="auto" id="miVideo">
                        <source src={require("../assets/fondoMovimiento.MP4")} type="video/mp4" />
                    </video>
                </div>
                <VStack>
                    <Text className="parrafos">
                        Somos constructora Summa una empresa de calidad, con personal altamente calificado, y con alta experiencia en el área.
                        Cuentenos el proyecto de sus sueños, y le garantizamos que se lo haremos realidad; Summa le ofrece una empresa dedicada a la construcción de diferentes tipos de obras residenciales,
                        comerciales, infraestructura y más, diferenciándonos por superar con vehemencia las expectativas y exigencias de nuestros clientes.
                    </Text>
                </VStack>
                <div id='hStackNosotrosDates'>
                    <Box>
                        <Text className="textNosotros--Number ">
                            +20
                        </Text>
                        <Text className="textNosotros--Data">
                            Años de Experiencia
                        </Text>
                    </Box>
                    <Box>
                        <Text className="textNosotros--Number ">
                            +20
                        </Text>
                        <Text className="textNosotros--Data">
                            Proyectos Finalizados
                        </Text>
                    </Box>
                    <Box>
                        <Text className="textNosotros--Number ">
                            +20
                        </Text>
                        <Text className="textNosotros--Data">
                            Clientes Satisfechos
                        </Text>
                    </Box>
                </div>
            </div>

            <div>
                <ul className="ulContent--Nosotros">
                    {listOptions.map((option, index) => {
                        return (
                            <li
                                id={index}
                                key={index}
                                onClick={() => {
                                    setSeccion(option.text)
                                    setActiveLink(index)
                                }}
                                className={`${activeLink === index ? "active--Nosotros" : ""}`}
                            >
                                {option.text}
                            </li>
                        );
                    })}
                </ul>
                {datesViewSeccionInfo}
            </div>

            <div>
                <Text className='title'>
                    Historia
                </Text>
                <SmartSlider buttonShape='round' slides={slidesArray} autoSlide={false} height='400px' />
            </div>

            <div>
                <Text className='title'>
                    Fundadores
                </Text>
                <div className="divNosotrosFundadores">
                    <Box>
                        <Text className="parrafos">
                            Más de 30 años de experiencia en el campo, amplio conocimiento en proyectos de construcción,
                            iniciando su labor como constructor y finalmente ejerciendo como gerente y director de proyectos.
                        </Text>
                        <Text className="subTitle">
                            Presidente
                        </Text>
                        <Text className="subTitle">
                            Co-Fundador
                        </Text>
                        <Text className="subTitle">
                            Freddy García Mora
                        </Text>
                    </Box>
                    <Box>
                        <Text className="parrafos">
                            Amplia experiencia en materiales de construcción, diseño de infraestructura tanto  tradicional como por medio de sistemas de drenajes sostenibles, tramitación de proyectos  como condominios residenciales, parques industriales, casas y apartamentos.
                        </Text>
                        <Text className="subTitle">
                            Director de proyectos
                        </Text>
                        <Text className="subTitle">
                            Co-Fundador, Ingeniero civil
                        </Text>
                        <Text className="subTitle">
                            Robin Alpízar Leiva
                        </Text>
                    </Box>
                    <Box>
                        <Text className="parrafos">
                            Amplia experiencia en ejecución de proyectos de infraestructura, condominios y parques industriales,
                            además de un extenso conocimiento en la planificación y ejecución de proyectos de estructuras metálicas.
                        </Text>
                        <Text className="subTitle">
                            Director de proyectos
                        </Text>
                        <Text className="subTitle">
                            Co-Fundador, Ingeniero en Construcción
                        </Text>
                        <Text className="subTitle">
                            Yehudy García Mora
                        </Text>
                    </Box>
                </div>
            </div>
        </div>
    </>
}