import ProjectContent from "../componets/projectContent";

// Casas
export function CasaValleDelSol() {
    return <>
        <ProjectContent
            title="Residencia Valle del Sol"
            ubication="Lindora, San Jose"
            progress={100}
            description="Contrato por mano de obra gris y blanca, por 532 metros cuadrados de construcción, 
            con muros perimetrales y accesos, además de contar con 2 niveles.
            "
            url1="/ValleDelSol/foto1.jpg"
            url2="/ValleDelSol/foto2.jpg"
            url3="/ValleDelSol/foto3.jpg" />
    </>
}

export function CasaBetzabeZuniga() {
    return <>
        <ProjectContent
            title="Residencia Betzabe Zuñiga"
            ubication=" Condominio Altabrisa, Grecia, Alajuela"
            progress={100}
            description="Proyecto llave en mano: Diseño, tramitología, materiales y mano de obra, para un total de 145 m2 de construcción. Alcance se limita a obra gris, obra blanca, eléctrico y mecánico, sistema constructivo Panelco, acabados con repello fino y empaste, pisos en porcelanato rectificado. "
            url1="/CasaBetza/foto1.jpeg"
            url2="/CasaBetza/foto2.jpeg"
            url3="/CasaBetza/foto3.jpeg" />
    </>
}

export function CasaAnaMariaSorcigli() {
    return <>
        <ProjectContent
            title="Residencia Sorcigli"
            ubication="Ojochal, Osa, Puntarenas"
            progress={100}
            description=""
            url1="/Srosigli/foto1.jpeg"
            url2="/Srosigli/foto2.jpeg"
            url3="/Srosigli/foto3.jpeg" />
    </>
}


export function CasaLuisMora() {
    return <>
        <ProjectContent
            title="Residencia Luis"
            ubication="Bahia Ballena, Osa, Puntarenas"
            progress={100}
            description="Proyecto llave en mano: Tramitología, materiales y mano de obra, para un total de 90 m2 de construcción, más terraza y piscina. Alcance se limita a obra gris, obra blanca, eléctrico y mecánico, sistema tipo prefablock, acabados con repello, pisos en porcelanato. También se contempla la construcción de una piscina de 12 m2 con su respectivos acabados, equipos y cuarto de máquinas, además de tapia perimetral con portón metálico y su respectivo motor."
            url1="/CasaLuisMora/foto1.jpeg"
            url2="/CasaLuisMora/foto2.jpeg"
            url3="/CasaLuisMora/foto3.jpeg" />
    </>
}


export function CasaAli() {
    return <>
        <ProjectContent
            title="Residencia Ali"
            ubication="Uvita, Osa, Puntarenas"
            progress={100}
            description="Proyecto por mano de obra, un total de 120 m2 de construcción, más terraza y piscina. Alcance se limita a obra gris, obra blanca, eléctrico y mecánico, sistema de mampostería integral, acabados con repello fino y empaste, pisos en porcelanato rectificado. También se contempla la construcción de una piscina de 11,5 m2 con su respectivos acabados, equipos y cuarto de máquinas."
            url1="/CasaAli/foto1.jpg"
            url2="/CasaAli/foto2.jpg"
            url3="/CasaAli/foto3.jpg" />
    </>
}

export function CasaLuz() {
    return <>
        <ProjectContent
            title="Proyecto Luz"
            ubication="Ojochal, Osa, Puntarenas"
            progress={60}
            description="Proyecto por mano de obra, un total de 2800 m2 de construcción de área de vivienda, más terrazas, muros de retención y piscina. Alcance se limita a obra gris hasta repellos finos."
            url1="/CasaLuz/foto1.jpg"
            url2="/CasaLuz/foto2.jpeg"
            url3="/CasaLuz/foto3.jpg" />
    </>
}

export function CasaKarina() {
    return <>
        <ProjectContent
            title="Residencia Karina"
            ubication="Uvita, Osa, Puntarenas"
            progress={100}
            description="Proyecto llave en mano: Tramitología, materiales y mano de obra."
            url1="/CasaKarina/foto1.jpg"
            url2="/CasaKarina/foto2.jpg"
            url3="/CasaKarina/foto3.jpg" />
    </>
}

export function CasaEsena() {
    return <>
        <ProjectContent
            title="Residencia Escena"
            ubication="Uvita, Osa, Puntarenas"
            progress={100}
            description="Proyecto por mano de obra, un total de 220 m2 de construcción, más terraza y piscina. Alcance se limita a obra gris, obra blanca, eléctrico y mecánico, sistema de mampostería integral, acabados con repello fino y empaste, pisos en porcelanato rectificado. También se contempla la construcción de una piscina de 10 m2 con su respectivos acabados, equipos y cuarto de máquinas."
            url1="/CasaEscena/foto1.jpeg"
            url2="/CasaEscena/foto2.jpg"
            url3="/CasaEscena/foto3.jpg" />
    </>
}

// Remodelaciones
export function RemodelacionHotelCastillo() {
    return <>
        <ProjectContent
            title="Remodelación Hotel El Castillo"
            ubication="Ojochal, Osa, Puntarenas"
            progress={100}
            description="Proyecto por mano de obra consiste en la remodelación total del hotel baños, hitaciones ,pisos ,pintura ,áreas nuevas como terrazas, deck en madera, yacusi ,bar etc,ubicado en ojochal de osa"
            url1="/RemodelacionCastillo/foto1.png"
            url2="/RemodelacionCastillo/foto2.png"
            url3="/RemodelacionCastillo/foto3.png" />
    </>
}

// Inspecciones
export function  InpeccionDeObraEsparza() {
    return <>
        <ProjectContent
            title="Inspección de obra Ezparza"
            ubication="Ezparza, Puntarenas"
            progress={100}
            description="Descripción: Proyecto por inspección de obra a cargo de los ingenieros de Summa, inspección semanal mediante visita al proyecto, bitácora y reporte de visita.
            Construcción de vivienda de 180m2 en dos niveles, en sistema de mampostería tipo superblock, acabados en repello fino y empaste, incluye pisos en porcelanato y cielo en tablilla PVC. 
            También se contempla la construcción de una piscina de 18m2 con su respectivos equipos y cuarto de máquinas.
            "
            url1="/InspeccionEzparza/foto1.jpg"
            url2="/InspeccionEzparza/foto2.jpg"
            url3="/InspeccionEzparza/foto3.jpg" />
    </>
}


export function  InpeccionDeObraCoyol() {
    return <>
        <ProjectContent
            title="Inspección de obra Coyol"
            ubication="Coyol, Alajuela"
            progress={100}
            description="nspección general de obra, incluyendo cronogramas, informes de avance, tablas de pago, control de calidad, dirección de tareas de ruta crítica, coordinada entre cliente y Constructora, entrega de proyecto.
            El proyecto fue una remodelación en el centro comercial de la zona franca."
            url1="/InspeccionCoyol/foto1.jpg"
            url2="/InspeccionCoyol/foto1.jpg"
            url3="/InspeccionCoyol/foto1.jpg" />
    </>
}

export function  InpeccionDeObraG3() {
    return <>
        <ProjectContent
            title="Inspección de obra"
            ubication="Orotina"
            progress={100}
            description="Proyecto por inspección de obra a cargo de los ingenieros de Summa, inspección semanal mediante visita al proyecto, bitácora y reporte de visita.
            Construcción de vivienda de 115m2 en sistema de mamposteria tipo superblock, acabados en repello fino y empaste, incluye pisos en porcelanato y cielo en tablilla PVC. También se contempla la construcción de una piscina de 18m2 con su respectivos equipos y cuarto de máquinas."
            url1="/InspeccionG3/foto1.jpg"
            url2="/InspeccionG3/foto2.jpg"
            url3="/InspeccionG3/foto3.jpg" />
    </>
}

// Otros
export function TrabajoEnPisicinaBahiaBallena() {
    return <>
        <ProjectContent
            title=" Trabaajo en piscina"
            ubication="Bahia Ballena, Osa, Puntarenas"
            progress={100}
            description="Proyecto llave en mano: Tramitología, materiales y mano de obra, para construcción de una piscina de 12 m2 con su respectivos acabados, equipos y cuarto de máquinas, y terraza perimetral."
            url1="/TrabajoPiscina/foto1.jpg"
            url2="/TrabajoPiscina/foto2.jpg"
            url3="/TrabajoPiscina/foto3.jpg" />
    </>
}

export function MantenimientoDeTuberias() {
    return <>
        <ProjectContent
            title="Mantenimiento de Tuberias"
            ubication="Bahia Ballena, Osa, Puntarenas"
            progress={100}
            description="Partes de otros trabajos que realizamos"
            url1="/TrabajoPiscina/foto1.jpg"
            url2="/TrabajoPiscina/foto2.jpg"
            url3="/TrabajoPiscina/foto3.jpg" />
    </>
}


export function InstalacionDePortones() {
    return <>
        <ProjectContent
            title="Instalacion de Portones"
            ubication="Bahia Ballena, Osa, Puntarenas"
            progress={100}
            description="Partes de otros trabajos que realizamos"
            url1="/CasaAli/foto1.jpg"
            url2="/CasaAli/foto2.jpg"
            url3="/CasaAli/foto3.jpg" />
    </>
}

export function  CalleThomasFarm() {
    return <>
        <ProjectContent
            title="Camino Thomas Farm"
            ubication="Uvita, Osa, Puntaremas"
            progress={100}
            description="Proyecto por mano de obra consiste en colocar dos huellas en concreto de 1 metro de ancho para carros y dos de block zacate de 80cm de ancho en finca privada en un tramo de 2 kilómetros de largo y 5 metros de ancho"
            url1="/CalleThomas/foto1.jpg"
            url2="/CalleThomas/foto2.jpg"
            url3="/CalleThomas/foto3.jpg" />
    </>
}

export function  CampoDeTiro() {
    return <>
        <ProjectContent
            title="Campo de tiro"
            ubication="Uvita, Osa, Puntaremas"
            progress={100}
            description="Partes de otros trabajos que realizamos"
            url1="/CampoDeTiro/foto1.jpg"
            url2="/CampoDeTiro/foto2.jpeg"
            url3="/CampoDeTiro/foto3.jpeg" />
    </>
}