import { Box, Container, Image, Text, VStack } from "@chakra-ui/react"
import { motion, useAnimationControls } from "framer-motion"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { HovereProjects, GratParent, fadeIn } from "../componets/animations"
import CabeceraSitio from "../componets/CabeceraSitio"
import proyectos from './proyectos.json'


export default function Proyectos() {
    const [data, setData] = useState(proyectos)
    const [filtro, setfiltro] = useState("General")
    const [activeLink, setActiveLink] = useState(0);

    useEffect(() => {
        if (filtro != "General") {
            setData(proyectos.filter(isFilter));
        } else {
            setData(proyectos);
        }
    }, [filtro])


    const handleClickLi = (option, index) => {
        setfiltro(option.text);
        setActiveLink(index);
    }

    const listOptions = [
        { text: "General" },
        { text: "Construcciones" },
        { text: "Inspecciones de obra" },
        { text: "Remodelaciones" },
        { text: "Carreteras" },
        { text: "Otros" },
    ];

    const isFilter = (value) => {
        return filtro === value.tipo
    };

    return <>
        <CabeceraSitio seccion={"Proyectos"} />
        <div className="divProyectosUlProyectos">
            <ul id="ulProyectos">
                {listOptions.map((option, index) => {
                    return (
                        <li
                            key={index}
                            onClick={() => handleClickLi(option, index)}
                            id={index}
                            className={`${activeLink === index ? "activeli" : ""}`}
                        >
                            {option.text}
                        </li>
                    );
                })}
            </ul>
        </div>
        <div className="divDefultConfig--Proyectos">
            <motion.div className="divProyectossection"
                layout
            >
                {data.map((proyect) =>
                    <ComponetProject key={proyect.id} url={proyect.urlImage} title={proyect.title} ubication={proyect.ubicacion} link={proyect.link} />
                )}
            </motion.div>
        </div>
    </>
}



const ComponetProject = ({ url, ubication, title, link }) => {
    return <>
        <Link className="aProjectsP" to={link}>
            <motion.div
                className="divProyectoContent"
                variants={HovereProjects}
                initial="offscreen"
                whileInView="onscreen"
                whileHover="hovered"
                layout
            >
                <Container className="divProyectoContentImage" backgroundImage={require(`../assets${url}`)} backgroundPosition="center" backgroundRepeat="no-repeat" backgroundSize="cover" >
                    <div className="divProyectoContentImageHover">
                        <Text>
                            Ver Proyecto
                        </Text>
                    </div>
                    <div className="divProyectoContentLineaVertical--one">

                    </div>
                    <div className="divProyectoContentLineaVertical--two">

                    </div>
                    <div className="divProyectoContentLineaVertical--third">

                    </div>
                    <div className="divProyectoContentLineaVertical--four">

                    </div>
                </Container>
                <VStack alignItems="flex-start" marginTop="20px" >
                    <Text >{ubication}</Text>
                    <Text className="subTitle">{title}</Text>
                </VStack>
            </motion.div>
        </Link>
    </>
}
