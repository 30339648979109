import { withTheme } from "@emotion/react";

export const GratParent = {
    offscreen: {
        opacity: 0,
    },
    onscreen: {
        opacity: 1,
        transition: {
            staggerChildren: 0.5
        }
    }
};

export const HovereProjects = {
    offscreen: {
        opacity: 0,
        scale: 0,
    },
    onscreen: {
        opacity: 1,
        scale: 1,
        zindex: 0,
        borderRadius: "10px",
        backgroundColor: "rgba(255,255,255, 0)",
        transition: {
            delay: 0.2,
            duration: 0.1,
            type: "spring", stiffness: 70
        }
    },

    hovered: {
        zindex:3,
        scale: 1,
        paddig: "0px",
        borderRadius: "10px",
        backgroundColor: "#ffffff",
        transition: {
            duration: 0.1,
            ease: "linear",
        }
    },
};


export const animationCuadrados = (xEntry, xExit) => (
    {
        offscreen: {
            opacity: 0,
            y: xEntry
        },
        onscreen: {
            opacity: 1,
            y: xExit,
            transition: {
                type: "spring",
                stiffness: 100
            }
        }
    }
)

export const animationCuadradosY = (yEntry, yExit) => (
    {
        offscreen: {
            opacity: 0,
            y: yEntry
        },
        onscreen: {
            opacity: 1,
            y: yExit,
            transition: {
                type: "spring",
                stiffness: 100
            }
        }
    }
)

export const ChildParent = {
    offscreen: {
        opacity: 0,
    },
    onscreen: {
        opacity: 1,
        transition: {
            staggerChildren: 0.3
        }
    }
};

export const ChildParentGrid = {
    offscreen: {
        opacity: 0,
    },
    onscreen: {
        opacity: 1,
    }
};


export const fadeup = {
    offscreen: {
        opacity: 0,
        y: -50
    },
    onscreen: {
        opacity: 1,
        y: 0,
        transition: {
            type: "spring",
            stiffness: 100
        }
    }
};


export const fadeDown = {
    offscreen: {
        opacity: 0,
        y: 50
    },
    onscreen: {
        opacity: 1,
        y: 0,
        transition: {
            type: "spring",
            stiffness: 100
        }
    }
};

export const fadeIn = {
    offscreen: {
        opacity: 0,
        scale: 0
    },
    onscreen: {
        opacity: 1,
        scale: 1,
        transition: {
            type: "spring",
            stiffness: 100
        }
    },
};

export const fadeleft = {
    offscreen: {
        opacity: 0,
        x: -50
    },
    onscreen: {
        opacity: 1,
        x: 0,
        transition: {
            type: "spring",
            stiffness: 100
        }
    }
};

export const fadeRight = {
    offscreen: {
        opacity: 0,
        x: 150
    },
    onscreen: {
        opacity: 1,
        x: 0,
        transition: {
            type: "spring",
            stiffness: 70
        }
    }
};

export const widhtIncrease = {
    offscreen: {
        opacity: 0,
        scaleX: 0
    },
    onscreen: {
        opacity: 1,
        scaleX: 1,
        transition: {
            type: "spring",
            stiffness: 100
        }
    }
};