import { Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";

export default function CabeceraSitio({ seccion }) {
    return <>
        <div className="divCabeceraSitio">
            <Text>Inicio {">"}{' '}{seccion}</Text>
            <div id="divCabeceraSitioCuadrado">
                <div id="textCabeceraSitioCajaTriangulo">
                </div>
                <div>
                    <Text id='textCabeceraSitioCaja' fontSize={['40px', '25px']} color='white' >
                        {seccion}
                    </Text>
                </div>
            </div>
        </div>
    </>
}