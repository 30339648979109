
import { useMemo } from 'react'
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api'

export default function Maps(){
    const center = useMemo(() => ({lat:9.3726549798280, lng:  -83.70745641135328}), [])

    const {isLoaded} = useLoadScript({
        googleMapsApiKey:process.env.REACT_APP_NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
    });

    if(!isLoaded) return <div>...Cargando</div>
    return <GoogleMap zoom={12} center={center} mapContainerClassName='map-container'>
    </GoogleMap>
}

