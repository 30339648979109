import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormControl, FormLabel, FormErrorMessage, Button, Input, HStack, Text, VStack, Textarea } from '@chakra-ui/react'
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import CabeceraSitio from "../componets/CabeceraSitio";
import MapSection from '../componets/Maps' // import the map here
import { Link } from "react-router-dom";
import emailjs from '@emailjs/browser';
import { useEffect, useRef } from "react";
import { useState } from "react";
import { motion, useAnimationControls } from "framer-motion";
import { fadeleft } from "../componets/animations"
const ButtonMailto = ({ mailto, label }) => {
    return (
        <Link
            className="buttonContactosEmail"
            to='#'
            onClick={(e) => {
                window.location.href = mailto;
                e.preventDefault();
            }}>
            {label}
        </Link>
    );
};


export default function Contactos() {
    const controls = useAnimationControls();
    const [cargando, setCargando] = useState(false);
    const [submitting, setSubmitting] = useState("");
    const [showButton, setShowButton] = useState(true);

    const form = useRef();

    const sendEmail = () => {
        setShowButton(false);
        setCargando(true);
        emailjs
            .sendForm(
                process.env.REACT_APP_SERVICE_ID,
                process.env.REACT_APP_TEMPLATE_ID,
                form.current,
                process.env.REACT_APP_PUBLIC_KEY
            )
            .then(
                (result) => {
                    setSubmitting("El mensaje se ha enviado exitosamente.");
                    controls.start("onscreen");
                    setCargando(false);
                },
                (error) => {
                    setSubmitting("El mensaje no se ha podido enviar debido a:", error.Text);
                    controls.start("onscreen");
                }
            );
    };

    useEffect(() => {
        let timer;
        if (!showButton) {
            timer = setTimeout(() => {
                setShowButton(true);
                setSubmitting("");
                controls.start("offscreen");
            }, 5000);
        }
        return () => clearTimeout(timer);
    }, [showButton]);



    return <>
        <CabeceraSitio seccion='Contacto' />
        <div className="divContactofondoGeneral">
            <div className="divDefultConfig--Contacto">
                <HStack w='100%'
                    borderColor="#932F2F"
                    borderStyle="solid"
                    borderWidth="5px"
                    spacing="0px"
                >
                    <VStack w='50%' className="hStackContactoInfo" spacing={4}>

                        {/* Contactos */}
                        <div>
                            <Text className="subTitle--Contacto">
                                Contactenos:
                            </Text>
                            <HStack>
                                <FontAwesomeIcon className='IconSocial' icon="fa-brands fa-whatsapp" />
                                <Text className="parrafos--Contacto">
                                    (+506) 60051672
                                </Text>
                            </HStack>
                            <HStack>
                                <FontAwesomeIcon className='IconSocial' icon="fa-brands fa-whatsapp" />
                                <Text className="parrafos--Contacto">
                                    (+506) 85890233
                                </Text>
                            </HStack>
                            <HStack>
                                <FontAwesomeIcon className='IconSocial' icon="fa-brands fa-whatsapp" />
                                <Text className="parrafos--Contacto">
                                    (+506) 84350357
                                </Text>
                            </HStack>
                        </div>

                        {/* Emails*/}
                        <div>
                            <Text className="subTitle--Contacto">
                                Envianos un email:
                            </Text>
                            <HStack className="divContactoCorreoSingle">
                                <FontAwesomeIcon icon="fa-solid fa-at" />
                                <ButtonMailto className="Mailto" label="construtora@summacr.com" mailto="mailto:construtora@summacr.com" />
                            </HStack>
                            <HStack className="divContactoCorreoSingle">
                                <FontAwesomeIcon icon="fa-solid fa-at" />
                                <ButtonMailto className="Mailto" label="fgarcia@summacr.com" mailto="mailto:construtora@summacr.com" />
                            </HStack>
                            <HStack className="divContactoCorreoSingle">
                                <FontAwesomeIcon icon="fa-solid fa-at" />
                                <ButtonMailto className="Mailto" label="ygarcia@summacr.com" mailto="mailto:construtora@summacr.com" />
                            </HStack>
                            <HStack className="divContactoCorreoSingle">
                                <FontAwesomeIcon icon="fa-solid fa-at" />
                                <ButtonMailto className="Mailto" label="ralpizar@summacr.com" mailto="mailto:construtora@summacr.com" />
                            </HStack>
                        </div>

                        <MapSection /> {/* include it here */}
                    </VStack>

                    <Formik
                        initialValues={{
                            nombre: '',
                            correo: '',
                            asunto: '',
                            descripcion: ''
                        }}

                        validationSchema={Yup.object({
                            nombre: Yup.string()
                                .required('Requerido'),
                            correo: Yup.string()
                                .email('Invalid email address')
                                .required('Requerido'),
                            descripcion: Yup.string()
                                .required('Requerido'),
                        })}
                        onSubmit={sendEmail}
                    >
                        {(props) => (
                            <Form className="formContacto" ref={form}>
                                <HStack spacing='28' w='50%' >
                                    <VStack>
                                        <Field name='nombre'>
                                            {({ field, form }) => (
                                                <FormControl isInvalid={form.errors.nombre && form.touched.nombre}>
                                                    <FormLabel>Nombre</FormLabel>
                                                    <Input bg='white'
                                                        focusBorderColor='gray'
                                                        type='tel'
                                                        color='black'
                                                        {...field} />
                                                    <FormErrorMessage>{form.errors.nombre}</FormErrorMessage>
                                                </FormControl>
                                            )}
                                        </Field>
                                        <Field name='correo'>
                                            {({ field, form }) => (
                                                <FormControl isInvalid={form.errors.correo && form.touched.correo}>
                                                    <FormLabel>Correo electrónico</FormLabel>
                                                    <Input bg='white'
                                                        focusBorderColor='gray'
                                                        type='tel'
                                                        color='black'
                                                        {...field} />
                                                    <FormErrorMessage>{form.errors.correo}</FormErrorMessage>
                                                </FormControl>
                                            )}
                                        </Field>
                                        <Field name='asunto'>
                                            {({ field, form }) => (
                                                <FormControl isInvalid={form.errors.asunto && form.touched.asunto}>
                                                    <FormLabel>Asunto</FormLabel>
                                                    <Input bg='white'
                                                        focusBorderColor='gray'
                                                        type='tel'
                                                        color='black'
                                                        {...field} />
                                                    <FormErrorMessage>{form.errors.asunto}</FormErrorMessage>
                                                </FormControl>
                                            )}
                                        </Field>
                                        <Field name='descripcion'>
                                            {({ field, form }) => (
                                                <FormControl isInvalid={form.errors.descripcion && form.touched.descripcion}>
                                                    <FormLabel>Descripcion</FormLabel>
                                                    <Textarea bg='white'
                                                        focusBorderColor='gray'
                                                        type='tel'
                                                        color='black'
                                                        {...field} />
                                                    <FormErrorMessage>{form.errors.descripcion}</FormErrorMessage>
                                                </FormControl>
                                            )}
                                        </Field>
                                    </VStack>
                                </HStack>

                                <motion.h1 variants={fadeleft}
                                    initial="offscreen"
                                    animate={controls}
                                    className="h1MessajeSucces">
                                    {submitting}
                                </motion.h1>
                                {showButton && (
                                    <Button
                                        mt={4}
                                        colorScheme='red'
                                        type='submit'
                                        marginTop='10'
                                        rightIcon={<FontAwesomeIcon icon="fa-solid fa-arrow-right" />} id='btnInicioProyectos'>
                                        Enviar
                                    </Button>
                                )}
                                {cargando && <h1>Enviando...</h1>}
                            </Form>
                        )}
                    </Formik>
                </HStack>
            </div>
        </div>
    </>
}
