import { Box, HStack, Image, Progress, Text, VStack } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from 'react'
import CabeceraSitio from "./CabeceraSitio";
import Modals from "./Modals";
import { Navigate, useNavigate } from "react-router-dom"
import { useInView } from "react-intersection-observer";
import useWindowDimensions from "./useDimensions";
import { animateScroll as scroll } from "react-scroll";

export default function ProjectContent({ title, description, ubication, progress, url1, url2, url3 }) {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [focus, setfocus] = useState(false)
    const navigate = useNavigate();
    const { width } = useWindowDimensions();

    const slidesArray = [
        {
            url: require("../assets" + url1),
        },
        {
            url: require("../assets" + url2),
        },
        {
            url: require("../assets" + url3),
        },
    ];

    const sliderStyle = {
        width: "100%",
        position: "relative",
        height: width > 420 ? "400px" : "310px",
    };

    const dotsContainerStyles = {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        marginTop: width > 420 ? "20px" : "13px"
    };

    const dotStyle = {
        cursor: "pointer",
        width: "31%"
    };


    const goToSlide = (slideIndex) => {
        setCurrentIndex(slideIndex);
    }

    const goToPrevious = () => {
        const isfirstSlide = currentIndex === 0;
        const newIndex = isfirstSlide ? slidesArray.length - 1 : currentIndex - 1;

        setCurrentIndex(newIndex);
    }

    const goToNext = () => {
        const isLastSlide = currentIndex === slidesArray.length - 1;
        const newIndex = isLastSlide ? 0 : currentIndex + 1;

        setCurrentIndex(newIndex);
    }


    const conatinerStyle = {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    }

    const retroceder = () => (
        navigate("/Proyectos")
    )

    useEffect(() => {
        scroll.scrollToTop();
    }, [])

    return <>
        <CabeceraSitio seccion={`Proyectos > ${title}`} />
        <VStack className="divDefultConfig--Proyectos">
            <HStack w="100%" justifyContent="space-between" flexWrap="wrap" className="divProjectsContentDivider--General">
                <Box className="buttonProjectsBack" onClick={retroceder} cursor="pointer">
                    <FontAwesomeIcon icon="fa-solid fa-square-minus" />
                </Box>
                <Text className="title">
                    {title}
                </Text>
                <Box className="buttonProjectsBack">
                </Box>
            </HStack>
            <HStack w="100%" alignItems="flex-start">
                <VStack w="50%" >
                    <div style={conatinerStyle}>
                        <div style={sliderStyle} id="divProyectosImagesSingle" className="divReponsiveDesingContent">
                            <Box className="Arrowleft" onClick={goToPrevious}>
                                <FontAwesomeIcon icon="fa-solid fa-arrow-right-to-bracket" />
                            </Box>
                            <Box className="ArrowRight" onClick={goToNext}>
                                <FontAwesomeIcon icon="fa-solid fa-arrow-right-to-bracket" />
                            </Box>
                            <Box overflow="hidden" w="100%" h="100%" backgroundImage={slidesArray[currentIndex].url} backgroundPosition="center" backgroundRepeat="no-repeat" backgroundSize="cover">
                            </Box>
                            <Box className="lupa" onClick={() => setfocus(true)}>
                                <FontAwesomeIcon icon="fa-solid fa-magnifying-glass-plus" color="white" />
                            </Box>
                        </div>
                        <div style={dotsContainerStyles}>
                            {slidesArray.map((slide, slideIndex) => (
                                <div key={slideIndex} style={dotStyle} onClick={() => goToSlide(slideIndex)}>
                                    <Box height="150px" w="100%" backgroundImage={slidesArray[slideIndex].url} backgroundPosition="center" backgroundRepeat="no-repeat" backgroundSize="cover">
                                    </Box>
                                </div>
                            ))}
                        </div>
                    </div>
                </VStack>
                <VStack width="50%" alignItems="flex-start" paddingLeft="25px">

                    <Text w="100%" marginBottom="20px">
                        {description}
                    </Text>

                    <HStack w="100%" alignItems="flex-start" className="divProjectsContentDivider--progressSection">

                        <VStack alignItems="flex-start">
                            <Text>Ubicación:</Text>
                            <Text>{ubication}</Text>
                        </VStack>

                        <VStack width="100%" alignItems="flex-start" justifyContent="space-between" height="59px" paddingLeft="30px"  >
                            <HStack width="100%" justifyContent="space-between">
                                <Text>Progreso General:</Text>
                                <Text>{progress}%</Text>
                            </HStack>
                            <Progress hasStripe value={progress} colorScheme={"red"} width="100%" />
                        </VStack>

                    </HStack>
                </VStack>
            </HStack>
        </VStack>

        {focus && <Box className="lupa--Modal" onClick={() => setfocus(false)}>
            <FontAwesomeIcon icon="fa-solid fa-xmark" color="white" />
        </Box>}
        {focus && <Modals slides={slidesArray} current={currentIndex} />}
    </>
}

