import React from 'react';
import {
  ChakraProvider,
  theme
} from '@chakra-ui/react';
import '../src/styles/App.scss';
import Template from './componets/template';
import Inicio from './views/Inicio';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faTrowelBricks, faMagnifyingGlassPlus, faArrowRight, faAt, faArrowRightToBracket, faXmark, faSquareMinus } from '@fortawesome/free-solid-svg-icons'
import { Routes, Route, Navigate } from "react-router-dom"
import Nosotros from './views/Nosotros';
import Servicios from './views/Servicios';
import Contactos from './views/Contactos';
import Proyectos from './views/Proyectos';
import {
  CasaAli, CasaValleDelSol, CasaBetzabeZuniga, CasaAnaMariaSorcigli,
  CasaLuisMora, CasaLuz, CasaKarina, CasaEsena, RemodelacionHotelCastillo
  , InpeccionDeObraEsparza,InpeccionDeObraCoyol, TrabajoEnPisicinaBahiaBallena,
  MantenimientoDeTuberias, InstalacionDePortones, CalleThomasFarm, CampoDeTiro, InpeccionDeObraG3} from './proyectos/Proyectos';
library.add(fab, faTrowelBricks, faMagnifyingGlassPlus, faArrowRight, faAt, faArrowRightToBracket, faXmark, faSquareMinus)


function App() {

  return (
    <ChakraProvider theme={theme}>
      <Routes>
        <Route path="/" element={<Template id='bodyTemplate' />}>
          <Route index element={<Inicio />} />
          <Route path="Nosotros" element={<Nosotros />} />
          <Route path="Servicios" element={<Servicios />} />
          <Route path="Contacto" element={<Contactos />} />
          <Route path="Proyectos" element={<Proyectos />} />
          <Route path="Proyectos/ResidenciaBetzabeZuniga" element={<CasaBetzabeZuniga />} />
          <Route path="Proyectos/ResidenciaValleDelSol" element={<CasaValleDelSol />} />
          <Route path="Proyectos/ResidenciaAnaMariaSorcigli" element={<CasaAnaMariaSorcigli />} />
          <Route path="Proyectos/ResidenciaLuisMora" element={<CasaLuisMora />} />
          <Route path="Proyectos/ResidenciaKarina" element={<CasaKarina />} />
          <Route path="Proyectos/ResidenciaEscena" element={<CasaEsena />} />
          <Route path="Proyectos/ProyectoLuz" element={<CasaLuz />} />
          <Route path="Proyectos/RemodelacionCastillo" element={<RemodelacionHotelCastillo />} />
          <Route path="Proyectos/ResidenciaAli" element={<CasaAli />} />
          <Route path="Proyectos/InpeccionDeObraEsparza" element={<InpeccionDeObraEsparza />} />
          <Route path="Proyectos/InpeccionDeObraCoyol" element={<InpeccionDeObraCoyol />} />
          <Route path="/Proyectos/InpeccionDeObroG3" element={<InpeccionDeObraG3 />} />
          <Route path="Proyectos/TrabajoEnPisicinaBahiaBallena" element={<TrabajoEnPisicinaBahiaBallena />} />
          <Route path="Proyectos/MantenimientoDeTuberias" element={<MantenimientoDeTuberias />} />
          <Route path="Proyectos/InstalacionDePortones" element={<InstalacionDePortones />} />
          <Route path="Proyectos/CalleThomasFarm" element={<CalleThomasFarm />} />
          <Route path="Proyectos/CampodeTiro" element={<CampoDeTiro />} />
          <Route path="*" element={<Navigate raplace to="/" />} />
        </Route>
      </Routes>
    </ChakraProvider>
  );
}

export default App;
