import React, { useState } from 'react'
import { Box, Image } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Modals({ slides, current }) {
    const [currentIndex, setCurrentIndex] = useState(current);

    const goToPrevious = () => {
        const isfirstSlide = currentIndex === 0;
        const newIndex = isfirstSlide ? slides.length - 1 : currentIndex - 1;

        setCurrentIndex(newIndex);
    }

    const goToNext = () => {
        const isLastSlide = currentIndex === slides.length - 1;
        const newIndex = isLastSlide ? 0 : currentIndex + 1;

        setCurrentIndex(newIndex);
    }

    return (
        <>
            <div id="Overlay">
                <Box className="Arrowleft--Modal" onClick={goToPrevious}>
                    <FontAwesomeIcon icon="fa-solid fa-arrow-right-to-bracket" />
                </Box>
                <Box className="ArrowRight--Modal" onClick={goToNext}>
                    <FontAwesomeIcon icon="fa-solid fa-arrow-right-to-bracket" />
                </Box>
                <div id="ContenedorModal">
                    <Box overflow="hidden" w="100%" h="100%">
                        <Image src={slides[currentIndex].url} zIndex={0} height="100%" width="100%"/>
                    </Box>
                </div>
            </div>
        </>
    );
}