import { Box, Text, VStack, Image, Grid, GridItem, Button, HStack } from "@chakra-ui/react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { motion } from "framer-motion"
import { Link, NavLink } from "react-router-dom"
import SliderShow from "../componets/slidershow"

import { GratParent, ChildParent, ChildParentGrid, fadeup, fadeleft, fadeRight, widhtIncrease, fadeIn, animationCuadrados, fadeDown, animationCuadradosY } from "../componets/animations"
import useWindowDimensions from "../componets/useDimensions"

const Inicio = () => {
    return <>
        <SliderShow />
        <div className={"divBgSegundary"}>
            <motion.div className='divDefultConfig--Inicio'
                variants={GratParent}
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true, margin: "-120px" }}>
                <Box
                    width='40%'
                    as={motion.div}
                    variants={ChildParent}
                >
                    <motion.h1 className="title"
                        variants={fadeup}
                    >
                        ¿Quiénes Somos?
                    </motion.h1 >
                    <motion.h1 className="parrafos"
                        variants={fadeleft}>
                        Somos constructora Summa una empresa de calidad, con personal altamente calificado, y con alta experiencia en el área.
                    </motion.h1>
                </Box>
                <motion.div variants={fadeRight} className="divInicioBarraBlanca">
                </motion.div>
                <Box width='100%' height="100%"
                    as={motion.div}
                    variants={ChildParent}
                >
                    <Text variants={fadeleft} as={motion.p} className="parrafos" justifyContent="space-between">
                        Cuentenos el proyecto de sus sueños, y le garantizamos que se lo haremos realidad; Summa le ofrece una empresa dedicada a la construcción de diferentes tipos de obras residenciales,
                        comerciales, infraestructura y más, diferenciándonos por superar con vehemencia las expectativas y exigencias de nuestros clientes.
                    </Text>
                    <Button variants={widhtIncrease} as={motion.button} rightIcon={<FontAwesomeIcon icon="fa-solid fa-arrow-right" />} id='btnInicioProyectos'>
                        <Link to="/Nosotros">
                            Más de nosotros
                        </Link>
                    </Button>
                </Box>
            </motion.div>
        </div>
        <div className="divContanierFullWidht--Servicios">
            <motion.div className="divCuadradoRojo--Servicios"
                as={motion.div}
                variants={animationCuadrados(100, 0)}
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true, margin: "-120px" }}
            >
            </motion.div>

            <motion.div className="divCuadradoGris--Servicios"
                as={motion.div}
                variants={animationCuadrados(100, 0)}
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true, margin: "-120px" }}
            >
            </motion.div>

            <div className='divDefultConfig--Inicio'>
                <VStack w='100%' alignItems="flex-start" overflow="visible"
                    as={motion.div}
                    variants={GratParent}
                    initial="offscreen"
                    whileInView="onscreen"
                    viewport={{ once: true, margin: "-120px" }}
                >
                    <Text variants={fadeup} as={motion.p} className="title"
                        w="100%"
                    >Servicios</Text>
                    <motion.div variants={widhtIncrease} style={{ width: "100%", height: "3px", background: "#424242" }}>
                    </motion.div>
                    <motion.div id='divInicioServiciosContent'
                        as={motion.div}
                        variants={GratParent}>
                        <motion.div variants={fadeIn} className="divInicioServicio" >
                            <Image src={require("../assets/construccion.png")} />
                            <div className="divInicioServiosContentDescription">
                                <h1 className="subTitle" >
                                    Construcciones
                                </h1>
                                <Text className="parrafos">
                                    Nuestro personal se encuentra ampliamente capacitado para cualquier tipo de construcción llámese edificios, cabañas, residenciales entre otros...                                </Text>
                                <Link to="/Servicios" className="linkMoreGeneral">
                                    Ver más
                                    <FontAwesomeIcon icon="fa-solid fa-arrow-right" />
                                </Link>
                            </div>
                        </motion.div>
                        <motion.div variants={fadeIn} className="divInicioServicio">
                            <Image src={require("../assets/contrato.png")} />
                            <div className="divInicioServiosContentDescription">
                                <h1 className="subTitle" >
                                    Tramitología
                                </h1>
                                <Text className="parrafos">
                                    Contamos con una amplia experiencia en tramitación de condominios, habitaciones, apartamentos, casas y parques industriales.
                                </Text>
                                <Link to="/Servicios" className="linkMoreGeneral">
                                    Ver más
                                    <FontAwesomeIcon icon="fa-solid fa-arrow-right" />
                                </Link>
                            </div>
                        </motion.div>
                        <motion.div variants={fadeIn} className="divInicioServicio">
                            <Image src={require("../assets/renovacion.png")} />
                            <div className="divInicioServiosContentDescription">
                                <h1 className="subTitle" >
                                    Remodelaciones, ampliaciones y acabados finales
                                </h1>
                                <Text className="parrafos">
                                    Ofrecemos servicios en remodelaciones, ampliaciones y acabados finales de excelente calidad y precio para sus proyectos.
                                </Text>
                                <Link to="/Servicios" className="linkMoreGeneral">
                                    Ver más
                                    <FontAwesomeIcon icon="fa-solid fa-arrow-right" />
                                </Link>
                            </div>
                        </motion.div>
                        <motion.div variants={fadeIn} className="divInicioServicio">
                            <Image src={require("../assets/inspeccion.png")} />
                            <div className="divInicioServiosContentDescription">
                                <h1 className="subTitle" >
                                    Inspección de obra
                                </h1>
                                <Text className="parrafos">
                                    Déjenos llevar el proceso y la calidad de sus proyectos, para garantizarles, la correcta elaboración y finalización de estos.
                                </Text>
                                <Link to="/Servicios" className="linkMoreGeneral">
                                    Ver más
                                    <FontAwesomeIcon icon="fa-solid fa-arrow-right" />
                                </Link>
                            </div>
                        </motion.div>
                    </motion.div>
                </VStack>
            </div >
        </div>
        <ReposivbeGripReturn />
        <motion.div className="divContanierFullWidht"
            variants={GratParent}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, margin: "-120px" }}
        >
            <motion.div className="divCuadradoRojo--frase"
                variants={animationCuadradosY(100, 0)}
            >

            </motion.div>
            <motion.div className='divDefultConfig--IniFrase' p='0'

            >
                <HStack w='100%'>
                    <Box w='50%'
                        as={motion.div}
                        variants={ChildParent}
                    >
                        <Text className="subTitle" fontWeight={500}
                            as={motion.div}
                            variants={fadeup}
                        >
                            "Damos forma a nuestros edificios, luego ellos nos dan forma a nosotros."
                        </Text>
                        <Text className="title"
                            as={motion.div}
                            variants={fadeleft}
                        >
                            Winston Churchill
                        </Text>
                    </Box>
                    <div id='divInicioFondoFraseImagen'>
                    </div >
                </HStack>
            </motion.div>
        </motion.div>
    </>
}

export default Inicio


const ReposivbeGripReturn = () => {
    const { width } = useWindowDimensions();

    if (width > 420) {
        return (
            <div
                className='divBgSegundary'
                id="divfondoProyectos">
                <VStack className='divDefultConfig--Inicio' alignItems="flex-start" position="relative"
                    as={motion.div}
                    variants={GratParent}
                    initial="offscreen"
                    whileInView="onscreen"
                    viewport={{ once: true, margin: "-120px" }}>
                    <Text className="title"
                        variants={fadeup} as={motion.p}
                        w="100%"
                    >
                        Proyectos
                    </Text>
                    <motion.div variants={widhtIncrease} style={{ width: "100%", height: "3px", background: "white" }}></motion.div>
                    <Grid
                        as={motion.Grid}
                        templateRows='repeat(4 1fr)'
                        templateColumns='repeat(6, 1fr)'
                        w='100%'
                        height='1000px'
                        variants={ChildParentGrid}
                        className="gridProyectos"
                    >
                        <GridItem colSpan={1} />
                        <GridItem colSpan={1} />

                        <GridItem as={motion.div}
                            variants={fadeup}
                            colSpan={2}
                            className='gridInicioProyectos'
                            marginBottom="-236px" marginLeft="148px"
                        >
                            <Link to="Proyectos/ResidenciaAli">
                                <div id="divInicioProyectos5">
                                    <div id='divInicioProyectosContent5--Project1'>
                                    </div>
                                    <div className="divIncioProyectosHover--Pq ">
                                    </div>
                                    <FontAwesomeIcon icon="fa-solid fa-magnifying-glass-plus" color="white" className="iconInicioProjectos" />
                                </div>
                            </Link>
                        </GridItem>

                        <GridItem colSpan={1} />
                        <GridItem colSpan={1} />
                        <GridItem colSpan={1} />

                        <GridItem as={motion.div}
                            variants={fadeleft} colSpan={2} className='gridInicioProyectos'>
                            <Link to="Proyectos/ProyectoLuz">
                                <div id="divInicioProyectos4">
                                    <div id='divInicioProyectosContent4--Project2' >
                                    </div>
                                    <div className="divIncioProyectosHover--Gr">
                                    </div>
                                    <FontAwesomeIcon icon="fa-solid fa-magnifying-glass-plus" color="white" className="iconInicioProjectos" />
                                </div>
                            </Link>
                        </GridItem>
                        <GridItem as={motion.div}
                            variants={fadeRight} colSpan={2} className='gridInicioProyectos' marginLeft="295px">
                            <Link to="Proyectos/ResidenciaValleDelSol">
                                <div id="divInicioProyectos4">
                                    <div id='divInicioProyectosContent4--Project3' >
                                    </div>
                                    <div className="divIncioProyectosHover--Gr">
                                    </div>
                                    <FontAwesomeIcon icon="fa-solid fa-magnifying-glass-plus" color="white" className="iconInicioProjectos" />
                                </div>
                            </Link>
                        </GridItem>

                        <GridItem colSpan={1} />
                        <GridItem colSpan={1} />
                        <GridItem colSpan={1} />
                        <GridItem as={motion.div}
                            variants={fadeDown} colSpan={2} className='gridInicioProyectos' marginTop="-237px" marginLeft="146px">
                            <Link to="Proyectos/ResidenciaKarina">
                                <div id="divInicioProyectos5">
                                    <div id='divInicioProyectosContent5--Project4'>
                                    </div>
                                    <div className="divIncioProyectosHover--Pq ">
                                    </div>
                                    <FontAwesomeIcon icon="fa-solid fa-magnifying-glass-plus" color="white" className="iconInicioProjectos" />
                                </div>
                            </Link>
                        </GridItem>
                        <GridItem as={motion.div}
                            variants={ChildParent}
                            colSpan={3} >
                            <Text as={motion.p}
                                variants={fadeleft}
                                className="parrafos">
                                Hemos trabajado en gran variedad de proyectos,
                                los cuales han seguido un proceso de calidad, eficiencia y organización para su correcta iniciación, desarrollo y finalización.
                            </Text>
                            <Link to="/Proyectos">
                                <Button
                                    as={motion.button}
                                    variants={widhtIncrease}
                                    rightIcon={<FontAwesomeIcon icon="fa-solid fa-arrow-right" />} id='btnInicioProyectos'>
                                    Ver Proyectos
                                </Button>
                            </Link>
                        </GridItem>
                        <GridItem colSpan={2} >
                        </GridItem>
                    </Grid>
                </VStack>
            </div>
        )
    } else {
        return (
            <motion.div
                variants={GratParent}
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true, margin: "-120px" }}
                className='divBgSegundary'
                id="divfondoProyectos">
                <VStack className="gridProyectos">
                    <Text className="title"
                        variants={fadeup} as={motion.p}
                        w="100%"
                    >
                        Proyectos
                    </Text>
                    <motion.div variants={widhtIncrease} style={{ width: "100%", height: "3px", background: "white" }}></motion.div>
                    <motion.div
                        variants={fadeup}
                        className='gridInicioProyectos'
                    >
                        <Link to="Proyectos/ResidenciaAli">
                            <div id="divInicioProyectos5"
                            >
                                <div id='divInicioProyectosContent5--Resposive1'>
                                </div>
                                <div className="divIncioProyectosHover--Pq ">
                                </div>
                                <FontAwesomeIcon icon="fa-solid fa-magnifying-glass-plus" color="white" className="iconInicioProjectos" />
                            </div>
                        </Link>
                    </motion.div>
                    <motion.div
                        variants={fadeup}
                        className='gridInicioProyectos'
                    >
                        <Link to="Proyectos/ProyectoLuz">
                            <div id="divInicioProyectos5"
                            >
                                <div id='divInicioProyectosContent5--Resposive2'>
                                </div>
                                <div className="divIncioProyectosHover--Pq ">
                                </div>
                                <FontAwesomeIcon icon="fa-solid fa-magnifying-glass-plus" color="white" className="iconInicioProjectos" />
                            </div>
                        </Link>
                    </motion.div>
                    <motion.div
                        variants={fadeup}
                        className='gridInicioProyectos'
                    >
                        <Link to="Proyectos/ResidenciaValleDelSol">
                            <div id="divInicioProyectos5"
                            >
                                <div id='divInicioProyectosContent5--Resposive3'>
                                </div>
                                <div className="divIncioProyectosHover--Pq ">
                                </div>ResidenciaKarina
                                <FontAwesomeIcon icon="fa-solid fa-magnifying-glass-plus" color="white" className="iconInicioProjectos" />
                            </div>
                        </Link>
                    </motion.div>
                    <motion.div
                        variants={fadeup}
                        className='gridInicioProyectos'
                    >
                        <Link to="Proyectos/">
                            <div id="divInicioProyectos5"
                            >
                                <div id='divInicioProyectosContent5--Resposive4'>
                                </div>
                                <div className="divIncioProyectosHover--Pq ">
                                </div>
                                <FontAwesomeIcon icon="fa-solid fa-magnifying-glass-plus" color="white" className="iconInicioProjectos" />
                            </div>
                        </Link>
                    </motion.div>
                    <motion.div
                        variants={ChildParent} >
                        <Text as={motion.p}
                            variants={fadeleft}
                            className="parrafos">
                            Hemos trabajado en gran variedad de proyectos,
                            los cuales han seguido un proceso de calidad, eficiencia y organización para su correcta iniciación, desarrollo y finalización.
                        </Text>
                        <Link to="/Proyectos">
                            <Button
                                as={motion.button}
                                variants={widhtIncrease}
                                rightIcon={<FontAwesomeIcon icon="fa-solid fa-arrow-right" />} id='btnInicioProyectos'>
                                Ver Proyectos
                            </Button>
                        </Link>
                    </motion.div>
                </VStack >
            </motion.div >
        )
    }
}