import React from 'react'
import { Image, Text, VStack } from "@chakra-ui/react";

const SliderShow = () => {

    return <>
        <VStack w="100%" className='divSliderShowParent' position="relative" overflow="hidden" >
            <div className='divSliderLineRoja--right'>
            </div>
            <div className='divSliderLineRoja--left'>
            </div>
            <div className='divSliderLineBlanca--right'>
            </div>
            <div className='divSliderLineBlanca--left'>
            </div>
            <div className='conatinerStyle' >
                <Image className="imgSliderShow"
                    src={require("../assets/fondoSlider.jpeg")} zIndex={1}
                    height={"auto"} width="100%"
                    position="absolute" top="0px" left="0px"></Image>
                <Image
                    className='imgSliderShow--NoAnimated'
                    src={require("../assets/fondoSlider2.jpeg")}
                    zIndex={0} height={"auto"} width="100%"
                    position="absolute" top="0px" left="0px"
                ></Image>
            </div>
            <div id='divSliderShow'>
                <div>
                    <Text id='textSliderShow1' fontSize={['30px', '100px']} color='white'>{"Construyendo"}</Text>
                    <Text id='textSliderShow2' fontSize={['40px', '120px']} color='white'>{"Construyendo"}</Text>
                    <Text id='textSliderShow3' fontSize={['40px', '120px']} color='white'>{"Sueños"}</Text>
                    <Text id='textSliderShow4' fontSize={['30px', '100px']} color='white'>{"Sueños"}</Text>
                </div>
            </div>
            b
            <div id="divSliderShowCuadrado">
                <div id="triangulo-equilatero-bottom-right">
                </div>
                <div>
                    <Text id='textSliderShowCaja' fontSize={['40px', '25px']} color='white' >
                        + 20 años de experiencia
                    </Text>
                </div>
            </div>
        </VStack>
    </>
}

export default SliderShow