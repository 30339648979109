import { AtSignIcon, ExternalLinkIcon, HamburgerIcon } from "@chakra-ui/icons";
import {
    Link as Lin,
    Box, Center, InputGroup, InputLeftElement, Text, HStack, Image,
    Menu, MenuButton, MenuDivider, VStack, Input, Divider, IconButton, MenuList, MenuItem, Button
} from "@chakra-ui/react";
import { animateScroll as scroll } from "react-scroll";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import {
    Outlet,
    Link,
    useLocation
} from "react-router-dom";
import useWindowDimensions from "./useDimensions";
import emailjs from '@emailjs/browser';
import { Spinner } from '@chakra-ui/react';
import { FaCheck } from 'react-icons/fa';


const NavbarResposive = () => {
    const [navBarClass, setnvaBarClass] = useState("navBarNormal")
    const { width } = useWindowDimensions();
    const location = useLocation();

    const listOptions = [
        { text: "Inicio", route: "/Inicio" },
        { text: "Nosotros", route: "/Nosotros" },
        { text: "Servicios", route: "/Servicios" },
        { text: "Proyectos", route: "/Proyectos" },
        { text: "Contacto", route: "/Contacto" }
    ];
    const focusNav = () => {
        switch (location.pathname) {
            case "/": return 0;
            case "/Nosotros": return 1;
            case "/Servicios": return 2;
            case "/Proyectos": return 3;
            case "/Contacto": return 4;
            default: return 3;
        }
    }

    const [activeLink, setActiveLink] = useState(focusNav());

    useEffect(() => {
        const handleScroll = event => {
            if (window.scrollY >= 110) {
                setnvaBarClass("headerSticky")
            } else {
                setnvaBarClass("navBarNormal")
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [width]);

    useEffect(() => {
        scroll.scrollToTop();
    }, [activeLink, location])

    if (width > 420) {
        return <>
            <header className={navBarClass}>
                <div>
                    <Box w='20%'>
                        <Image src={require(navBarClass == "headerSticky" ? '../assets/logo-summa-color.png' : "../assets/logo-summa-blanco.png")} />
                    </Box>
                    <HStack w='60%' justifyContent='flex-end'>
                        <nav className="navBar">
                            {listOptions.map((option, index) => {
                                return (
                                    <Link
                                        key={index}
                                        id={index}
                                        onClick={() => setActiveLink(index)}
                                        className={`${activeLink === index ? "active " : ""}`}
                                        to={`${option.text}`}>{option.text}
                                    </Link>
                                );
                            })}
                        </nav>
                    </HStack>
                    <Center width='20px'>
                        <Divider orientation='vertical' />
                    </Center>
                    <HStack className='HstackSocialMedia'>
                        <a href="https://www.instagram.com/constructorasummagasa/?igshid=YmMyMTA2M2Y%3D" target="_blank" rel="noreferrer">
                            <FontAwesomeIcon className='IconSocial' icon="fa-brands fa-instagram" />
                        </a>
                        <a href="https://www.facebook.com/people/Summa-Ingenieros-y-Constructores/100089207988880/?mibextid=LQQJ4d" target="_blank" rel="noreferrer">
                            <FontAwesomeIcon className='IconSocialfb' icon="fab fa-facebook-f" />
                        </a>
                        <a href="https://wa.link/sjakdi" target="_blank" rel="noreferrer">
                            <FontAwesomeIcon className='IconSocial' icon="fa-brands fa-whatsapp" />
                        </a>
                    </HStack>
                </div>
            </header>
        </>
    } else {
        return <>
            <header className="navBarNormal">
                <div>
                    <Box w='20%'>
                        <Image src={require(navBarClass == "headerSticky" ? '../assets/logo-summa-color.png' : "../assets/logo-summa-blanco.png")} />
                    </Box>
                    <Menu
                        autoSelect={false}
                    >
                        <MenuButton
                            as={IconButton}
                            aria-label='Options'
                            _active={{ background: "#932F2F", color: "white" }}
                            borderStyle="none"
                            borderRadius="0px"
                            height="68px"
                            icon={<HamburgerIcon />}
                            variant='outline'
                        />
                        <MenuList
                            borderRadius="0px"
                            marginTop="-10px"
                        >
                            {listOptions.map((option, index) => {
                                return (
                                    <MenuItem
                                        as={Link}
                                        key={index}
                                        onClick={() => setActiveLink(index)}
                                        _active={{ background: "#932F2F", color: "white" }}
                                        style={activeLink === index ? { background: "#932F2F", color: "white" } : {}}
                                        to={`${option.text}`}>{option.text}
                                    </MenuItem>
                                );
                            })}

                            <MenuDivider />
                            <MenuItem >
                                <HStack className='HstackSocialMedia'>
                                    <a href="https://www.instagram.com/constructorasummagasa/?igshid=YmMyMTA2M2Y%3D" target="_blank" rel="noreferrer">
                                        <FontAwesomeIcon className='IconSocial' icon="fa-brands fa-instagram" />
                                    </a>
                                    <a href="https://www.facebook.com/people/Summa-Ingenieros-y-Constructores/100089207988880/?mibextid=LQQJ4d" target="_blank" rel="noreferrer">
                                        <FontAwesomeIcon className='IconSocialfb' icon="fab fa-facebook-f" />
                                    </a>
                                    <a href="https://wa.link/sjakdi" target="_blank" rel="noreferrer">
                                        <FontAwesomeIcon className='IconSocial' icon="fa-brands fa-whatsapp" />
                                    </a>
                                </HStack>
                            </MenuItem>
                        </MenuList>
                    </Menu>
                </div>
            </header>
        </>
    }
}



export default function Template() {
    const { width } = useWindowDimensions();
    const [formState, setFormState] = useState({ loading: false, success: false });
    const [email, setEmail] = useState("");


    const handleSubmit = (event) => {
        event.preventDefault();
        if (email.trim().length != 0) {
            setFormState({ ...formState, loading: true });

            emailjs.sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_FOOTERID, event.target, process.env.REACT_APP_PUBLIC_KEY)
                .then((result) => {
                    console.log(result.text);
                    setFormState({ ...formState, loading: false, success: true });
                }, (error) => {
                    console.log(error.text);
                    setFormState({ ...formState, loading: false });
                });
        }
    };

    useEffect(() => {
        let timer;
        if (formState.success) {
            timer = setTimeout(() => {
                setFormState({ ...formState, success: false });
            }, 5000);
        }
        return () => clearTimeout(timer);
    }, [formState.success]);



    return <>
        <NavbarResposive />
        <VStack w='100%' spacing='0'>

            <Outlet />

            <footer>
                <div className="divCuadradoRojo--footer">

                </div>
                <div className="footerHstackContainer">
                    <Box w='30%'>
                        <Image src={require('../assets/logo-summa-blanco.png')} />
                    </Box>
                    <Center width='10px'>
                        <Divider orientation='vertical' />
                    </Center>
                    <Box w='30%'>
                        <Text fontSize='xl'>
                            Navegar
                        </Text>
                        <ul id="ulFooter">
                            <li><Link to="/Inicio">Inicio</Link></li>
                            <li><Link to="/Nosotros">Nosotros</Link></li>
                            <li><Link to="/Servicios">Servicios</Link></li>
                            <li><Link to="/Proyectos">Proyectos</Link></li>
                            <li><Link to="/Contacto">Contacto</Link></li>
                        </ul>
                    </Box>
                    <Center width='10px'>
                        <Divider orientation='vertical' />
                    </Center>
                    <Box w='40%' alignSelf='flex-start'>
                        <form onSubmit={handleSubmit}>
                            <Text fontSize='xl'>Contacto:</Text>
                            <div className="divFooterContacto">
                                <InputGroup>
                                    <InputLeftElement
                                        pointerEvents='none'
                                        children={<AtSignIcon color='gray.400' />}
                                    />
                                    <Input
                                        name="correo"
                                        bg='white'
                                        focusBorderColor='#932F2F'
                                        type='email'
                                        placeholder='Correo'
                                        color='black'
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </InputGroup>
                                <Button type='submit' variant={formState.success ? 'success' : 'primary'} disabled={formState.loading}>
                                    {formState.loading ? <Spinner size='sm' /> : formState.success ? <FaCheck /> : 'Enviar'}
                                </Button>
                            </div>
                        </form>

                        <Text marginTop="10px" fontSize='xl'>Inscitos en:</Text>
                        <Image src={require("../assets/cfiaBlanco.jpg")} />
                    </Box>
                </div>
                <Center h='30px'>
                    <Divider borderBottomWidth='2px' borderBottomStyle='groove' borderBottomColor='#932F2F' />
                </Center>
                <VStack>
                    <Text textAlign='center' fontSize='md'>Todos los derechos reservados. ©</Text>
                    <HStack className='HstackSocialMedia' w={width > 412 ? '10%' : "30%"}>
                        <a href="https://www.instagram.com/constructorasummagasa/?igshid=YmMyMTA2M2Y%3D" target="_blank" rel="noreferrer">
                            <FontAwesomeIcon className='IconSocial' icon="fa-brands fa-instagram" />
                        </a>
                        <a href="https://www.facebook.com/people/Summa-Ingenieros-y-Constructores/100089207988880/?mibextid=LQQJ4d" target="_blank" rel="noreferrer">
                            <FontAwesomeIcon className='IconSocialfb' icon="fab fa-facebook-f" />
                        </a>
                        <a href="https://wa.link/sjakdi" target="_blank" rel="noreferrer">
                            <FontAwesomeIcon className='IconSocial' icon="fa-brands fa-whatsapp" />
                        </a>
                    </HStack>
                </VStack>
            </footer>/
        </VStack>
    </>
}
